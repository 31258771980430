import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useRequestContext } from '../../utils/requestContex';
import './SummaryTable.css';


function createData(groupSize, percGroup, percFound, percRootFound, percNotFound, reqsAwaitingResults) {
  return { groupSize, percGroup, percFound, percRootFound, percNotFound, reqsAwaitingResults };
}

export default function SummaryTable() {
    const { dataArray, updateDataDisplay, checkExistingAuditDate } = useRequestContext();
    const [ summaryRow, setSummaryRow ] = useState({});

  // updates summary table numbers
  useEffect(() => {
      let reqsWithResults = dataArray.length;
      let totalInGroup;
      if (dataArray.length) {
        totalInGroup = dataArray[0].group_size;
      } else {
        totalInGroup = null;
      }
      let reqsAwaitingResults = totalInGroup - reqsWithResults;

      let percTotalInGroup =
          (reqsWithResults >= 0 && totalInGroup && totalInGroup > 0) ?
          Math.round((reqsWithResults / totalInGroup) * 100) :
          '-';

      let targetFoundCount = 0;
      let rootFoundCount = 0;
      for (const obj of dataArray) {
          if (obj.link_detected === 1) {
              targetFoundCount++
          } else if (obj.link_detected === 0 && obj.root_detected === 1) {
              rootFoundCount++
          }
      }
      let targetFoundPercentage = (targetFoundCount >= 0 && reqsWithResults && reqsWithResults > 0) ?
          Math.round((targetFoundCount / reqsWithResults) * 100) : '-';
      let rootFoundPercentage = (rootFoundCount >= 0 && reqsWithResults && reqsWithResults > 0) ?
          Math.round((rootFoundCount / reqsWithResults) * 100) : '-';
      let neitherFoundPercentage = ((reqsWithResults - (targetFoundCount + rootFoundCount)) >= 0 && reqsWithResults && reqsWithResults > 0) ?
          Math.round(((reqsWithResults - (targetFoundCount + rootFoundCount)) / reqsWithResults) * 100) : '-';

      setSummaryRow(createData(totalInGroup, percTotalInGroup, targetFoundPercentage, rootFoundPercentage, neitherFoundPercentage, reqsAwaitingResults));
  }, [dataArray, checkExistingAuditDate])

  // initial data pull on page load
  useEffect(() => {
    updateDataDisplay();
    // eslint-disable-next-line
  }, []);

  // automatic data pull every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      updateDataDisplay();
      console.log('Performed automatic data pull');
    }, 30000)
    return () => clearInterval(interval)
  }, [updateDataDisplay])

return (
    <div className='infoContainer'>
        <div className='summaryTableDiv'>
            {dataArray.length ?
                <div className='groupInfoDiv'>
                    <h5>Currently displaying: <span className='groupInfo'>{dataArray[0].request_group}</span></h5>
                    <h5>Requested on: <span className='groupInfo'>{dataArray[0].requested_at.slice(0, 10)}</span></h5>
                </div>
            :
                <></>
            }
            <TableContainer sx={{  width: '100%', borderRadius: '5px' }}>
                <Table sx={{ maxWidth: '100%' }} size="small" aria-label="summary data table">
                    <TableHead>
                        <TableRow bgcolor='#e22d1c'>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                                Original Request Size
                            </TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
                                Posts Awaiting Results
                            </TableCell>
                            <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>
                                % Results Returned
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0, p: '1.3rem' }
                            }}
                            bgcolor='#ffffff'
                        >
                            <TableCell>
                                {summaryRow.groupSize} posts
                            </TableCell>
                            <TableCell align="center">
                                {summaryRow.reqsAwaitingResults} posts
                            </TableCell>
                            <TableCell align="right">
                                {summaryRow.percGroup}%
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    <TableHead>
                        <TableRow bgcolor='#e22d1b' sx={{ '& .MuiTableRow-root': {
                            whiteSpace: 'pre-line'}
                                }}>
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>
                                <span>Post Active</span><br></br>Target Link Found
                            </TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
                                <span>Post Active</span><br></br>Root Link Found
                            </TableCell>
                            <TableCell align="right" sx={{ color: 'white', fontWeight: 'bold' }}>
                                <span>Post Inactive</span><br></br>or Neither Found
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, p: '1.3rem' } }}  bgcolor='#ffffff'>
                            <TableCell sx={{ color: '#2a9d8f', fontWeight: 'bold' }}>
                                {summaryRow.percFound}%
                            </TableCell>
                            <TableCell sx={{ color: '#e36414', fontWeight: 'bold' }} align="center">
                                {summaryRow.percRootFound}%
                            </TableCell>
                            <TableCell sx={{ color: '#A4161A', fontWeight: 'bold' }} align="right">
                                {summaryRow.percNotFound}%
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </div>
);
}
