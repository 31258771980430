import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";

import { useRequestContext } from '../../../utils/requestContex';

function createData(
    orderId,
    xOrderId,
    linkDetected,
    rootDetected,
    siteIndexed,
    anchorText,
    postUrl,
    postStatus,
    userId,
    orderDate,
    completionDate,
    targetUrl,
    promisedMetrics,
    linkDestination,
    relAttr,
    requestGroup,
    requestedAt,
    returnedAt,
    adminUrl
) {
  return {
    orderId,
    xOrderId,
    orderDate,
    completionDate,
    linkDetected,
    rootDetected,
    siteIndexed,
    anchorText,
    postUrl,
    postStatus,
    userId,
    targetUrl,
    promisedMetrics,
    linkDestination,
    relAttr,
    requestGroup,
    requestedAt,
    returnedAt,
    adminUrl
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // conditional color-coding for rows based on post status
  const green = '#2a9d8f';
  const orange = '#e36414';
  const red = '#A4161A';
  let rowStyle = {
    color: '#484747'
  };

  if (row.linkDetected === 0 && row.rootDetected === 0) {
    rowStyle['color'] = red
  } else if (row.linkDetected === 0 && row.rootDetected === 1) {
    rowStyle['color'] = orange
  } else if (row.linkDetected === 1 && row.rootDetected === 1) {
    rowStyle['color'] = green
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          '& .MuiTableCell-body': { wordBreak: 'break-all' }
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" style={rowStyle} sx={{
          '.MuiTableCell-root': {
            width: '10%'
          }
        }}>
          {row.completionDate}
        </TableCell>
        <TableCell align="center" style={rowStyle} sx={{
          '.MuiTableCell-root': {
            width: '10%'
          }
        }}>
          <a href={row.adminUrl} target='_blank' rel="noreferrer" style={{textDecoration: 'none', ...rowStyle}}>
            {row.orderId}
          </a>
        </TableCell>
        <TableCell align="center" style={rowStyle} sx={{
          '.MuiTableCell-body': {
            width: '5%',
            wordBreak: 'normal'
          }
        }}>
          {row.xOrderId}
        </TableCell>
        <TableCell align="center" style={rowStyle}>{row.linkDetected}</TableCell>
        <TableCell align="center" style={rowStyle}>{row.rootDetected}</TableCell>
        <TableCell align="center" style={rowStyle} sx={{
          '.MuiTableCell-root': {
            width: '10%'
          }
        }}>
          {row.siteIndexed === false && <XMarkIcon style={{color: red, height: '2rem'}}/>}
          {row.siteIndexed === true && <CheckIcon style={{color: green, height: '2rem'}}/>}
        </TableCell>
        <TableCell align="center" style={rowStyle}>{row.anchorText}</TableCell>
        <TableCell align="right">
          <a
            href={row.postUrl}
            target='_blank'
            rel='noreferrer'
            style={rowStyle}
          >
            {row.postUrl}
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, padding: '1rem' }} bgcolor='#d9d5d5'>
              {/* details section */}
              <Typography variant="h6" gutterBottom component="div" >
                Audit Details
              </Typography>
              <Table size="small" aria-label="post status information">
                <TableHead>
                  <TableRow>
                    <TableCell>Information</TableCell>
                    <TableCell align="right">Order Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody bgcolor='white'>
                    <TableRow key={row.userId}>
                      <TableCell component="th" scope="row">{row.postStatus}</TableCell>
                      <TableCell component="th" scope="row" align="right">{row.orderDate}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              <Table size="small" aria-label="anchor tag information" >
                <TableHead>
                  <TableRow>
                    <TableCell>Order URL</TableCell>
                    <TableCell>Link Destination</TableCell>
                    <TableCell align="right">Relational Attributes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody  bgcolor='white'>
                    <TableRow key={row.userId}>
                      <TableCell component="th" scope="row"><a href={row.adminUrl} target='_blank' rel='noreferrer'>
                        {row.adminUrl}</a>
                      </TableCell>
                      <TableCell component="th" scope="row"><a href={row.linkDestination} target='_blank' rel='noreferrer'>
                        {row.linkDestination}</a>
                      </TableCell>
                      <TableCell align="right">{row.relAttr}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              {/* userId, orderDate, targetUrl, promisedMetrics */}
              <Table size="small" aria-label="audit request information">
                <TableHead>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    {/* <TableCell>Order Date</TableCell> */}
                    <TableCell>Target URL</TableCell>
                    <TableCell align="right">Promised Metrics</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody  bgcolor='white'>
                    <TableRow key={row.requestId}>
                      <TableCell component="th" scope="row">{row.userId}</TableCell>
                      <TableCell><a href={row.targetUrl} target='_blank' rel='noreferrer'>{row.targetUrl}</a></TableCell>
                      <TableCell align="right" component="th" scope="row">{row.promisedMetrics}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
              {/* request group, requestedAt, returnedAt */}
              <Table size="small" aria-label="audit request information">
                <TableHead>
                  <TableRow>
                    <TableCell>Request Group</TableCell>
                    <TableCell align="right">Requested At</TableCell>
                    <TableCell align="right">Returned At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody  bgcolor='white'>
                    <TableRow key={row.requestId}>
                      <TableCell component="th" scope="row">{row.requestGroup}</TableCell>
                      <TableCell align="right">{row.requestedAt}</TableCell>
                      <TableCell align="right">{row.returnedAt}</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ResultsTable() {
  const { dataArray } = useRequestContext();
  const rows = dataArray.map((item) => {
    return createData(
      item.order_id,
      item.hothx_order_id,
      item.link_detected,
      item.root_detected,
      item.site_indexed,
      item.link_anchor_text,
      item.post_url,
      item.post_status,
      item.user_id,
      item.order_date,
      item.completion_date,
      item.target_url,
      item.promised_metrics,
      item.link_destination,
      item.link_rel_attributes,
      item.request_group,
      item.requested_at,
      item.returned_at,
      item.admin_url
    )
  })

  return (
    <TableContainer component={Paper} sx={{ m: '1.5rem', p: '1.5rem', width: '90%' }}>
      <Table aria-label="collapsible table">
        <TableHead bgcolor='#d9d5d5' sx={{ display: 'table-header-group' }}>
          <TableRow>
            <TableCell sx={{ width: '5%' }} />
            <TableCell align="center" sx={{ width: '10%' }}>Completion Date</TableCell>
            <TableCell align="center" sx={{ width: '10%' }}>Order ID</TableCell>
            <TableCell align="center" sx={{ width: '10%' }}>HOTHX Order ID</TableCell>
            <TableCell align="center" sx={{ width: '8%' }}>Target Link Detected?</TableCell>
            <TableCell align="center" sx={{ width: '8%' }}>Root Link Detected?</TableCell>
            <TableCell align="center" sx={{ width: '10%' }}>Site Indexed?</TableCell>
            <TableCell align="center" sx={{ width: '25%' }}>Anchor Text</TableCell>
            <TableCell align="right" sx={{ width: '25%' }}>Post URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Row key={i} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
