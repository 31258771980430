import React, { useState, createContext, useContext, useCallback } from 'react';

const RequestContext = createContext();

export const useRequestContext = () => useContext(RequestContext);

export default function RequestContextProvider(props) {
    const [ dataArray, setDataArray ] = useState([]);
    const [ resultFilterRoute, setResultFilterRoute ] = useState('/working-first');
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ auditRequested, setAuditRequested ] = useState(false);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkExistingAuditDate = useCallback(async (auditData) => {

        let now = new Date();
        let year = now.getFullYear().toString();
        let month = (now.getMonth() + 1).toString();
        if (month.length === 1) {
            month = `0${month}`
        }
        let day = now.getDate().toString();
        if (day.length === 1) {
            day = `0${day}`
        }
        let dateNow = `${year}-${month}-${day}`;
        let lastAuditDate = auditData[0].requested_at.slice(0, 10);

        if (dateNow === lastAuditDate && !auditRequested) {
            console.log('Audit already requested today.')
            setAuditRequested(true);
        }
    }, [auditRequested]);


    const updateDataDisplay = useCallback(async () => {
        let path = window.location.pathname;
        let sliceIndex = path.indexOf('/', 1);
        let id = path.slice(1, sliceIndex);

        const response = await fetch('https://la-cf-queues.nnm.workers.dev/api/results/userid', {
            method: 'POST',
            body: JSON.stringify({ id })
        });

        const data = await response.json();
        if (data.length) {
            let requestGroup = data[0].request_group;

            const response = await fetch(`https://la-cf-queues.nnm.workers.dev/api/results/request-group${resultFilterRoute}`, {
                method: 'POST',
                body: JSON.stringify({ requestGroup })
            });

            const data2 = await response.json();

            let newDataStr = JSON.stringify(data2);
            let existingDataStr = JSON.stringify(dataArray)
            if (newDataStr === existingDataStr) {
                setIsLoaded(true);
            } else {
                setDataArray(data2);
                setIsLoaded(true);
            }
            delay(10000);
        } else {
            console.log('Data not yet returned.')
        }
        // eslint-disable-next-line
    }, [resultFilterRoute])

    return (
        <RequestContext.Provider
            value={{
                dataArray,
                setDataArray,
                updateDataDisplay,
                resultFilterRoute,
                setResultFilterRoute,
                isLoaded,
                delay,
                auditRequested,
                setAuditRequested,
                checkExistingAuditDate
            }}
        >
            {props.children}
        </RequestContext.Provider>
    );
}
