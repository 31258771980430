import React, { useEffect } from 'react';
import './ResultsDisplay.css';
import ResultsTable from './ResultsTable/ResultsTable';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useRequestContext } from '../../utils/requestContex';
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

function ResultsDisplay() {
    const {
        dataArray,
        updateDataDisplay,
        resultFilterRoute,
        setResultFilterRoute
    } = useRequestContext();

    const updateFilterRoute = (event) => {
        setResultFilterRoute(event.target.value);
    };

    useEffect(() => {
        updateDataDisplay();
    },[resultFilterRoute])

    return (
        <>
        <div className='resultsContainer'>
            <div className='actionBtnsDiv'>
                <button
                    className='refreshBtn'
                    title="Refresh data display"
                    onClick={updateDataDisplay}>
                        <i className="fa-solid fa-rotate-right"></i>
                </button>
                {(dataArray.length && dataArray[0].order_id !== null) ?
                    <>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-by-select">Filter by...</InputLabel>
                                <Select
                                labelId="filter-by-select"
                                id="demo-simple-select"
                                value={resultFilterRoute}
                                label="Filter by..."
                                onChange={updateFilterRoute}
                                >
                                    <MenuItem value={'/working-first'}>Working posts first</MenuItem>
                                    <MenuItem value={'/problems-first'}>Broken posts first</MenuItem>
                                    <MenuItem value={'/problems-only '}>Only broken posts</MenuItem>
                                    <MenuItem value={'/working-only'}>Only working posts</MenuItem>
                                    <MenuItem value={'/chronological'}>Chronological</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <CSVLink
                            className='CSVLink downloadBtn'
                            data={dataArray}
                            filename={`${dataArray[0].user_id}-linkaudit-${dataArray[0].requested_at}.csv`}
                            title="Download as CSV">
                                <i className="fa-solid fa-file-arrow-down"></i>
                        </CSVLink>
                    </> : null
                }
            </div>
            {dataArray.length && dataArray[0].order_id === null ?
                <>
                    <ExclamationCircleIcon style={{color: 'white', height: '4rem'}}/>
                    <h2 style={{color: 'white'}}>
                        No links to audit for the selected product(s) and/or time interval.
                    </h2>
                </>
            :
                <ResultsTable />
            }
        </div>
        </>
    );
}

export default ResultsDisplay;
