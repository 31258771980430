import './App.css';
import ResultsDisplay from './components/ResultsDisplay/ResultsDisplay';
import { useRequestContext } from './utils/requestContex';
import SummaryTable from './components/SummaryTable/SummaryTable';
import SettingsForm from './components/SettingsForm/SettingsForm';
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

function App() {
    const { isLoaded } = useRequestContext();

    return (
        <main>
            <div className='resultsContainer' style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '92%'
            }}>
                <SummaryTable />
                <SettingsForm />
            </div>
            {!isLoaded ? <CircularProgress color='primary' /> : <></>}
            <ResultsDisplay />
        </main>
    );
}

export default App;
