import { createTheme } from '@mui/material/styles';

// https://mui.com/material-ui/customization/theming/
const theme = createTheme({
    palette: {
        primary: {
            main: '#e22d1c',
        },
        secondary: {
            main: '#f2f2f2',
        },
    },
    // typography: {
    //     button: {
    //         textTransform: 'none'
    //     }
    // }
});
export default theme;