import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom/client';
import RequestContextProvider from './utils/requestContex';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RequestContextProvider>
                <App />
            </RequestContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);
