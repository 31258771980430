import React, { useState } from 'react';
import { useRequestContext } from "../../utils/requestContex";

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export default function SettingsForm() {
    const { auditRequested, setAuditRequested } = useRequestContext();
    const [ domainfragment, setDomainfragment ] = useState(null);
    const [ product, setProduct ] = useState(null);
    const [ interval, setInterval ] = useState('1 year');
    const [ indexRequested, setIndexRequested ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ modalMessage, setModalMessage ] = useState('');


    const handleClose = () => {
        setOpen(false);
    };

    const requestNewAudit = async () => {
        let path = window.location.pathname;
        let sliceIndex = path.indexOf('/', 1);
        let id = path.slice(1, sliceIndex);
        let hash = path.slice(sliceIndex + 1);

        let reqBody = {
            userid: id,
            hash: hash
        };
        if (domainfragment) {
            reqBody.domainfragment = domainfragment;
        }

        let now = new Date();
        let startDate;

        if (interval === '2 months') {
            now.setMonth(now.getMonth() - 2);
        }
        if (interval === '6 months') {
            now.setMonth(now.getMonth() - 6);
        }
        if (interval === '1 year') {
            now.setFullYear(now.getFullYear() - 1);
        }
        if (interval === '2 years') {
            now.setFullYear(now.getFullYear() - 2);
        }
        if (interval) {
            now = now.toLocaleDateString().split('/');
            let month = now[0].length === 1 ? `0${now[0]}` : now[0];
            let day = now[1].length === 1 ? `0${now[1]}` : now[1];
            let year = now[2];
            startDate = `${year}-${month}-${day}`
            reqBody.startDate = startDate;
        }
        if (!domainfragment && (!interval || interval === '2 years')) {
            setModalMessage('To prevent very long data returns, when pulling a link audit for all domains, the time interval must be the past 1 year or less.')
            setOpen(true);
            return;
        }
        if (product) {
            reqBody.product = product;
        }
        reqBody.indexRequested = indexRequested;
        console.log(reqBody);

        try {
            await fetch('https://la-cf-queues.nnm.workers.dev/new-request', {
                method: "POST",
                body: JSON.stringify(reqBody),
                headers: {
                    "Content-Type": "application/json",
                },
                mode: 'no-cors'
            });
            console.log('New audit requested!')
            setAuditRequested(true);
        } catch (error) {
            console.error('Something went wrong...')
            console.error(error)
        }
    }

    return (
        <div className='infoContainer'>
            <div className='summaryTableDiv'>
                {/* domain fragment input */}
                <TextField
                    label="Target Domain (optional)"
                    onChange={(event) => {setDomainfragment(event.target.value)}}
                    sx={{
                        width: '100%',
                        margin: '1rem',
                        '& .MuiInputBase-root': {
                            background: 'white'
                        }
                    }}
                />
                <div className='newAuditReqDiv'>
                    {/* product selector */}
                    <Box sx={{ width: '50%', marginBottom: '1rem' }}>
                        <FormControl fullWidth>
                            <InputLabel id="product-select-label">Product</InputLabel>
                            <Select
                                labelId="product-select-label"
                                id="product-select"
                                value={product}
                                label="Product"
                                onChange={(event) => {
                                    setProduct(event.target.value)
                                }}
                                sx={{background: 'white'}}
                            >
                                <MenuItem value={null}>All Products</MenuItem>
                                <MenuItem value='gp'>Guest Post/Link Outreach</MenuItem>
                                <MenuItem value='foundations'>Foundations</MenuItem>
                                <MenuItem value='syndication'>Syndication</MenuItem>
                                <MenuItem value='linkinsertions'>Link Insertions</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    {/* interval selector */}
                    <Box sx={{ width: '50%', marginLeft: '1rem', marginBottom: '1rem' }}>
                        <FormControl fullWidth >
                            <InputLabel id="interval-select-label">Interval</InputLabel>
                            <Select
                                labelId="interval-select-label"
                                id="interval-select"
                                value={interval}
                                label="Interval"
                                onChange={(event) => {
                                    setInterval(event.target.value)
                                }}
                                sx={{background: 'white'}}
                            >
                                <MenuItem value={null}>All Time</MenuItem>
                                <MenuItem value='2 months'>Past 2 Months</MenuItem>
                                <MenuItem value='6 months'>Past 6 Months</MenuItem>
                                <MenuItem value='1 year'>Past Year</MenuItem>
                                <MenuItem value='2 years'>Past 2 Years</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </div>
                {/* indexing input */}
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(event) => {
                                setIndexRequested(!indexRequested);
                            }}
                        />
                    }
                    label="Check to see if live sites are indexed."
                />
                {/* submit button */}
                <Button
                    variant="contained"
                    onClick={requestNewAudit}
                    sx={{
                        marginTop: '1rem',
                    }}
                >
                    Request a New Link Audit
                </Button>
                {auditRequested &&
                    <div style={{textAlign: 'center', color: 'white', fontWeight: 'bolder', marginBottom: '1rem'}}>
                        <p>Audit successfully requested! It may take a while to load completely. Additional requests will delay processing.</p>
                    </div>
                }
            </div>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {modalMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Okay</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}
